:root {
  --brand-color: #9d84f7;
}

body {
  background-color: var(--background);
}

.background.left {
  position: absolute;
  left: 0px;
  top: 0px;
  width: 130vw;
  transform: translate(-50%, -50%) rotate(225deg);
  opacity: 0.2;
  fill: var(--brand-color);
}

.background.right {
  position: absolute;
  bottom: 0px;
  right: 0px;
  width: 120vw;
  transform: translate(50%, 50%) rotate(180deg);
  opacity: 0.6;
  fill: var(--brand-color);
}

.background.left.mobile {
  width: 200vw;
}

.background.right.mobile {
  opacity: 0.1;
  width: 200vw;
  bottom: auto;
  top: 300px;
}
